import { Box } from '@radix-ui/themes';
import { useParams } from 'react-router-dom';

import { useAttachments } from 'services/attachments';

const AttachmentsScreen = () => {
  const { id } = useParams();
  const { data } = useAttachments(id as string);

  return (
    <Box width="100vw" height="100vh">
      <iframe
        src={data?.url}
        width="100%"
        height="100%"
        style={{ border: 0, outline: 'none' }}
      />
    </Box>
  );
};

export default AttachmentsScreen;

import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';

import type { ApiError, ApiResponse } from 'entities/Response';
import { baseApi } from 'libs/axios';
import parseData from 'utils/parseData';

import { createUseContact, createUseContacts } from './keys';
import type {
  ContactData,
  ContactResponse,
  ContactsParams,
  ContactsResponse,
} from './types';

export const useContacts = (
  params?: ContactsParams,
  options?: Partial<
    UseQueryOptions<ContactsResponse, ApiError, ContactsResponse>
  >,
) => {
  return useQuery({
    queryKey: createUseContacts(params),
    queryFn: () =>
      baseApi
        .get<ApiResponse<ContactsResponse>>(`contacts`, { params })
        .then(parseData),
    ...options,
  });
};

// (
//   params: CompaniesParams,
//   options?: UseInfiniteQueryOptions<
//     CompaniesResponse,
//     ApiError,
//     InfiniteData<CompaniesResponse>
//   >,
// ) => {
//   return useInfiniteQuery({
//     queryKey: createUseCompanies(params),
//     queryFn: ({ pageParam = params.page }) =>
//       baseApi
//         .get(`companies`, {
//           params: {
//             page: pageParam,
//             ...params,
//           },
//         })
//         .then((r) => r.data),
//     initialPageParam: 1,
//     ...options,
//     getNextPageParam: ({ num_pages }) => {
//       return Math.ceil(num_pages / PAGE_SIZE) <= (params.page ?? 0)
//         ? (params.page ?? 0) + 1
//         : null;
//     },
//   });
// };

export const useContact = (
  contactId: string,
  options?: Partial<
    UseQueryOptions<ContactResponse, ApiError, ContactResponse>
  >,
) => {
  return useQuery({
    queryKey: createUseContact(contactId),
    queryFn: () => baseApi.get(`contacts/${contactId}`).then(parseData),
    ...options,
  });
};

export const useContactCreate = (
  options?: UseMutationOptions<ContactsResponse, ApiError, ContactData>,
) => {
  return useMutation({
    mutationFn: (data) => baseApi.post('contacts', data),
    ...options,
  });
};

export const useUpdateContat = (
  options?: UseMutationOptions<
    ContactsResponse,
    ApiError,
    Partial<ContactData & { id: number }>
  >,
) => {
  return useMutation({
    mutationFn: (data) => baseApi.patch(`contacts/${data.id}`, data),
    ...options,
  });
};

// newContact (client, { payload, config }) {
//   return client.post('contacts', payload, config)
// },

import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { ApiError, ApiResponse } from 'entities/Response';
import { baseApi } from 'libs/axios';
import parseData from 'utils/parseData';

import { createUseAttachments } from './keys';
import type { AttachmentsData, AttachmentsResponse } from './types';

export const useUpdateAttachments = (
  options?: UseMutationOptions<AttachmentsResponse, ApiError, AttachmentsData>,
) => {
  return useMutation<AttachmentsResponse, ApiError, AttachmentsData>({
    mutationFn: (data) =>
      baseApi
        .patch<ApiResponse<AttachmentsResponse>>(
          `attachments/${data.id}`,
          data.formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(parseData),
    ...options,
  });
};

export const useAttachments = (
  id: string,
  options?: UseQueryOptions<AttachmentsResponse, ApiError>,
) => {
  return useQuery({
    queryKey: createUseAttachments(id),
    queryFn: () => baseApi.get(`attachments/${id}`).then(parseData),
    ...options,
  });
};

/* eslint-disable complexity */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  ExclamationTriangleIcon,
  InfoCircledIcon,
  PaperPlaneIcon,
} from '@radix-ui/react-icons';
import {
  AlertDialog,
  Badge,
  Box,
  Button,
  Callout,
  Flex,
  Spinner,
  Strong,
} from '@radix-ui/themes';
import { formatDate } from 'date-fns';
import { Editor } from 'primereact/editor';
import { Toast } from 'primereact/toast';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useUpdateAttachments } from 'services/attachments';
import {
  useMail,
  useSendMail,
  useTemplatesMails,
  useUpdateEmail,
} from 'services/mails';
import { useCurrentUser, useSystemUser } from 'services/user';

import AttachmentsMail from 'components/AttachmentsMail';
import Container from 'components/Container';
import InputError from 'components/InputError';
import InputField from 'components/InputField';
import InputFile from 'components/InputFile';
import InputLabel from 'components/InputLabel';
import RecipientsDialog from 'components/RecipientsDialog';
import SelectField from 'components/SelectField';

import type { FormData } from './types';

const HistoricFormScreen = () => {
  const [openUpdateAlert, setOpenUpdateAlert] = useState<boolean>(false);
  const [openSendAlert, setOpenSendAlert] = useState<boolean>(false);

  const { id } = useParams();
  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<FormData>();

  const toastBC = useRef<Toast>(null);

  const recipients = watch('recipients') ?? [];
  const templateId = watch('template_id');

  const { data: user } = useCurrentUser();
  const { data: templates } = useTemplatesMails();
  const { mutateAsync: sendMail, isPending: isPendingSendMail } = useSendMail();

  const {
    data: mail,
    refetch: refetchMail,
    isLoading: isLoadingMail,
  } = useMail(id as string, {
    enabled: !!id,
  });

  const { data: systemUser, refetch: refetchSystemUser } = useSystemUser(
    mail?.sent_by_id as string,
    {
      enabled: Boolean(mail?.sent_by_id),
    },
  );

  const isDisabled = Boolean(mail?.sent_at);

  const { mutate: updateEmail, isPending: isPendingUpdateMail } =
    useUpdateEmail();

  const { mutate: updateAttachments, isPending: isPendingUpdateAttachments } =
    useUpdateAttachments();

  const isLoading = isPendingUpdateMail || isPendingUpdateAttachments;

  const template = useMemo(() => {
    return templates?.results.find((i) => i.id.toString() === templateId);
  }, [templates?.results, templateId]);

  const onSubmit = useCallback(
    (form: FormData) => {
      updateEmail(
        {
          id: mail?.id as number,
          variables: form.variables,
          recipients: form.recipients,
        },
        {
          onSuccess() {
            toastBC.current?.show({
              severity: 'success',
              summary: 'E-mail',
              detail: 'E-mail atualizado com sucesso.',
              life: 5000,
            });

            if (form.attachments?.length) {
              setOpenUpdateAlert(true);
            }
          },
        },
      );
    },
    [mail?.id, updateEmail],
  );

  const handleUpdateAttachments = useCallback(() => {
    const formData = new FormData();
    const file = getValues('attachments')?.[0];

    formData.append('content', file as File);
    updateAttachments(
      {
        id: mail?.attachments?.[0].id as number,
        formData,
      },
      {
        onSuccess() {
          refetchMail();
          setValue('attachments', undefined);

          toastBC.current?.show({
            severity: 'success',
            summary: 'Anexo',
            detail: 'Anexo atualizado com sucesso.',
            life: 5000,
          });
        },
      },
    );
  }, [getValues, mail?.attachments, refetchMail, setValue, updateAttachments]);

  const handeOnSendMail = useCallback(() => {
    sendMail(
      {
        id: mail?.id as number,
        user_id: user?.me.id as number,
      },
      {
        onSuccess() {
          refetchMail();
          setValue('attachments', undefined);

          toastBC.current?.show({
            severity: 'success',
            summary: 'E-mail',
            detail: 'E-mail enviado com sucesso.',
            life: 5000,
          });

          refetchSystemUser();
        },
      },
    );
  }, [
    mail?.id,
    refetchMail,
    refetchSystemUser,
    sendMail,
    setValue,
    user?.me.id,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (mail) {
        setValue('recipients', mail.recipients);
        setValue('template_id', mail.template_id);
        setValue('variables', mail.variables);

        refetchSystemUser();
      }
    }, 200);
  }, [mail, setValue, refetchSystemUser]);

  return (
    <Container loading={isLoadingMail}>
      {mail?.sent_at && (
        <Callout.Root mb="4" color="green">
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text>
            Este e-mail foi enviado no dia{' '}
            {formatDate(new Date(mail.sent_at), "dd/MM/yyyy 'às' HH:mm")} por{' '}
            {systemUser?.name}.
          </Callout.Text>
        </Callout.Root>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="template_id"
          render={({ field }) => (
            <SelectField
              label="Template Mailing"
              placeholder="Selecione um template"
              value={field.value}
              defaultValue={field.value}
              onValueChange={field.onChange}
              disabled={isDisabled}
              options={(templates?.results ?? []).map((i) => ({
                value: i.id.toString(),
                label: i.name,
              }))}
            />
          )}
        />

        {(mail?.recipients ?? []).length > 0 && (
          <>
            <InputLabel text="Destinatários" />
            <RecipientsDialog
              defaultValue={mail?.recipients ?? []}
              value={recipients}
              onChange={(recipients) => setValue('recipients', recipients)}
              disabled={isDisabled}
              trigger={
                <Box pb="5">
                  <Flex
                    className="recipients"
                    p="2"
                    gap="2"
                    style={{
                      borderColor:
                        recipients.length === 0
                          ? 'var(--red-11)'
                          : 'var(--gray-7)',
                    }}
                  >
                    {recipients.slice(0, 2).map((contact) => (
                      <Box key={contact.email}>
                        <Badge radius="full">{contact.email}</Badge>
                      </Box>
                    ))}

                    <Box>
                      {recipients.length > 2 && (
                        <Badge radius="full">+ {recipients.length - 2}</Badge>
                      )}
                    </Box>
                  </Flex>

                  {recipients.length === 0 && (
                    <InputError error="Nenhum contato selecionado" />
                  )}
                </Box>
              }
            />
          </>
        )}

        {template?.required_variables.includes('email_subject') && (
          <InputField
            label="Assunto"
            placeholder="Digite aqui o assunto"
            disabled={isDisabled}
            error={errors.variables?.email_subject?.message}
            {...register('variables.email_subject', {
              required: 'Assunto é obrigatório',
            })}
          />
        )}

        {template?.required_variables.includes('email_title') && (
          <InputField
            label="Título do e-mail"
            placeholder="Digite aqui o título"
            disabled={isDisabled}
            error={errors.variables?.email_title?.message}
            {...register('variables.email_title', {
              required: 'Título é obrigatório',
            })}
          />
        )}

        <Box mb="4">
          <Controller
            name="variables.email_body"
            control={control}
            render={({ field }) => (
              <Editor
                id={field.name}
                name={field.name}
                value={field.value}
                disabled={isDisabled}
                onTextChange={(e) => field.onChange(e.htmlValue)}
                style={{ height: '320px' }}
              />
            )}
          />

          {errors.variables?.email_body?.message && (
            <InputError error={errors.variables.email_body.message} />
          )}
        </Box>

        <InputFile
          error={errors.attachments?.message}
          {...register('attachments')}
        />

        {mail?.attachments && mail.attachments.length > 0 && (
          <AttachmentsMail
            filename={mail.attachments[0].filename}
            id={mail.attachments[0].id}
            url={mail.attachments[0].url}
          />
        )}

        <Flex gap="4" align="center" justify="between">
          <Button
            color="green"
            disabled={!mail?.sent_at}
            type="button"
            onClick={() => setOpenSendAlert((prevState) => !prevState)}
          >
            {isPendingSendMail ? <Spinner /> : <PaperPlaneIcon />}
            Enviar
          </Button>

          <Button
            type="submit"
            disabled={
              !isValid ||
              Boolean(mail?.sent_at && mail.attachments?.length === 0)
            }
          >
            {isLoading && <Spinner />}
            Salvar
          </Button>
        </Flex>
      </form>

      <Toast ref={toastBC} />

      <AlertDialog.Root open={openSendAlert} onOpenChange={setOpenSendAlert}>
        <AlertDialog.Content style={{ maxWidth: 470 }}>
          <AlertDialog.Title>Atenção</AlertDialog.Title>
          <AlertDialog.Description size="2">
            {mail?.sent_at ? (
              <>
                <p>
                  Você está enviando este e-mail{' '}
                  <strong>{mail.variables.email_subject}</strong> novamente, tem
                  certeza que deseja enviar?
                </p>
              </>
            ) : (
              <>
                <p>
                  Tem certeza que deseja enviar este e-mail?{' '}
                  <strong>{mail?.variables.email_subject}</strong>.
                </p>

                {recipients.length === 1 && (
                  <Callout.Root color="yellow" role="alert">
                    <Callout.Icon>
                      <ExclamationTriangleIcon />
                    </Callout.Icon>
                    <Callout.Text>
                      Você está enviando este e-mail apenas para o endereço
                      cadastrado <Strong>{recipients[0].email}</Strong>.
                    </Callout.Text>
                  </Callout.Root>
                )}
              </>
            )}{' '}
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancelar
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action onClick={handeOnSendMail}>
              <Button variant="solid" color="green">
                Sim, quero enviar
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>

      <AlertDialog.Root
        open={openUpdateAlert}
        onOpenChange={setOpenUpdateAlert}
      >
        <AlertDialog.Content style={{ maxWidth: 450 }}>
          <AlertDialog.Title>Atenção</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Você está alterando este anexo, o mesmo será atualizado para os
            contatos que receberam este e-mail, tem certeza dessa alteração?
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancelar
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action onClick={handleUpdateAttachments}>
              <Button variant="solid" color="green">
                Sim, quero alterar
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </Container>
  );
};

export default HistoricFormScreen;

import { useCallback, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'config/routes';
import { CookieKey, getCookie, removeCookie, setCookie } from 'utils/cookies';

const useAuth = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const token = useMemo(() => {
    if (typeof document === `undefined`) {
      return undefined;
    }

    return getCookie(CookieKey.AuthToken, document.cookie);
  }, []);

  const login = useCallback(
    (token: string) => {
      setCookie(CookieKey.AuthToken, token);

      navigate(`${ROUTES.PRIVATE.ROOT}/${ROUTES.PRIVATE.DASHBOARD}`);
    },
    [navigate],
  );

  const logout = useCallback(() => {
    removeCookie(CookieKey.AuthToken);

    // Reset store currentUser
    // setCurrentUser(undefined);

    // Invalid queries
    queryClient.invalidateQueries();

    navigate(ROUTES.PUBLIC.ROOT);
  }, [queryClient, navigate]);

  return {
    login,
    logout,
    token,
  };
};

export default useAuth;

import { useCallback, useEffect, useRef } from 'react';

import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Box, Button, Callout, Flex, Switch, Text } from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { Toast } from 'primereact/toast';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import type { Company } from 'services/companies/types';
import { useContact, useUpdateContat } from 'services/contacts';
import { createUseContacts } from 'services/contacts/keys';

import Container from 'components/Container';
import IconLoading from 'components/IconLoading';
import InputField from 'components/InputField';

import type { FormData } from './types';

const ContactDetail = () => {
  const { id } = useParams();
  const { data: contact, isLoading } = useContact(id as string, {
    enabled: !!id,
  });

  const queryClient = useQueryClient();
  const { mutate, isPending } = useUpdateContat();

  const toastBC = useRef<Toast>(null);

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    if (contact) {
      setValue('name', contact.name);
      setValue('email', contact.email);
      setValue('is_active', contact.is_active);
      setValue('phone', contact.phone);
    }
  }, [contact, setValue]);

  const onSubmit = useCallback(
    (form: FormData) => {
      mutate(
        {
          id: Number(id),
          email: form.email,
          is_active: form.is_active,
          name: form.name,
          phone: form.phone,
        },
        {
          onSuccess() {
            queryClient.refetchQueries({
              queryKey: createUseContacts(),
            });
            toastBC.current?.show({
              severity: 'success',
              summary: 'Contato',
              detail: 'Contato atualizado com sucesso.',
              life: 5000,
            });
          },
          onError() {
            toastBC.current?.show({
              severity: 'error',
              summary: 'Contato',
              detail: 'Erro ao atualizar contato, tente novamente.',
              life: 5000,
            });
          },
        },
      );
    },
    [id, mutate, queryClient],
  );

  return (
    <Container title="Contatos" loading={isLoading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box maxWidth="800px">
          {contact && (contact?.companies ?? []).length !== 0 && (
            <Callout.Root color="yellow" mb="4">
              <Callout.Icon>
                <InfoCircledIcon />
              </Callout.Icon>
              <Callout.Text>
                Este contato está vinculado aos seguintes clientes:{' '}
                {Object.keys(contact.companies)
                  .flatMap(
                    (i) =>
                      (contact.companies[i as never as number] as Company).name,
                  )
                  .join(', ')}
              </Callout.Text>
            </Callout.Root>
          )}

          <Controller
            control={control}
            name="is_active"
            render={({ field }) => (
              <Text as="label" size="2">
                <Flex gap="2" mb="4">
                  <Switch
                    defaultChecked={field.value}
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />{' '}
                  Ativo
                </Flex>
              </Text>
            )}
          />

          <InputField
            label="Nome"
            placeholder="Digite o nome aqui"
            error={errors.name?.message}
            {...register('name', {
              required: 'Nome é obrigatório',
            })}
          />

          <InputField
            label="E-mail"
            placeholder="Digite o e-mail aqui"
            error={errors.name?.message}
            {...register('email', {
              required: 'E-mail é obrigatório',
            })}
          />

          <InputField
            label="Telefone"
            placeholder="Digite o telefone aqui"
            mask={['(dd) dddd-dddd', '(dd) ddddd-dddd']}
            {...register('phone')}
          />
          <Flex gap="4">
            <Flex
              style={{
                flex: 1,
              }}
            >
              {/* {data?.id && data?.name && (
              <AlertDialog.Root>
                <AlertDialog.Trigger>
                  <Button color="crimson" variant="soft" type="button">
                    Excluir
                  </Button>
                </AlertDialog.Trigger>
                <AlertDialog.Content maxWidth="450px">
                  <AlertDialog.Title>Atenção</AlertDialog.Title>
                  <AlertDialog.Description size="2">
                    Você está prestes a excluir um cliente. Tem certeza de
                    deseja excluir o(a) <Strong>{data.name}</Strong>?
                  </AlertDialog.Description>

                  <Flex gap="3" mt="4" justify="end">
                    <AlertDialog.Cancel>
                      <Button variant="soft" color="gray">
                        Cancelar
                      </Button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action>
                      <Button
                        variant="solid"
                        color="red"
                        onClick={removeCompany}
                        loading={isPendingDeleteCompany}
                      >
                        Excluir
                      </Button>
                    </AlertDialog.Action>
                  </Flex>
                </AlertDialog.Content>
              </AlertDialog.Root>
            )} */}
            </Flex>

            <Flex justify="end">
              <Button size="3" type="submit" disabled={isPending}>
                {isPending && <IconLoading />} Salvar
              </Button>
            </Flex>
          </Flex>
        </Box>

        <Toast ref={toastBC} />
      </form>
    </Container>
  );
};

export default ContactDetail;
